<template>
  <a-field
    :type="fieldType"
    :field="field"
    :label="null"
  >
    <slot
      v-if="$slots.label"
      name="label"
    />
    <p class="control">
      <span class="button is-static">{{ doc.id }}</span>
    </p>
    <b-input
      ref="input"
      v-bind="$attrs"
      :value="value"
      :disabled="disabled"
      :type="type"
      :icon="updateOnClick ? 'magnify' : ''"
      icon-clickable
      @input="update"
      @icon-click="onClick"
      @keyup.native.enter="onEnter"
    />
  </a-field>
</template>

<script>

import Mixin from './Mixin'
import AField from '@/components/Form/AField'

export default {
  components: { AField },
  mixins: [Mixin],
  props: {
    type: {
      type: String,
      default: 'text'
    },
    updateOnClick: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fieldType() {
      return this.doc && (this.doc.id === 'en' || this.doc.id === 'cs') && (this.value === '__MISSING_TRANSLATION__' || !this.value) ? 'is-danger' : ''
    }
  },
  methods: {
    onInput () {
      const value = this.$refs.input.newValue
      if (!this.updateOnClick) {
        this.update(value)
      } else {
        if(!value) {
          this.update(value)
        }
      }
    },
    onClick () {
      const value = this.$refs.input.newValue
      this.update(value)
    },
    onEnter(ev) {
      if (this.updateOnClick) {
        this.onClick()
      }
    }
  }

}
</script>
