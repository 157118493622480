<style scoped>
  .menu-list li ul {
    margin: 0;
  }

  .menu-list li .node-label {
    margin: 0;
    font-weight: 600;
  }

  /deep/ table.table th {
    color: gray;
    font-weight: 300;
  }

  /deep/ table.table {
    border: 1px solid lightgray;
  }

  /deep/ .hidden-thead thead {
    display: none;
  }

  /deep/ .panel-block {
    font-style: italic;
    font-size: small;
  }
</style>

<template>
  <li
    :key="id"
    class="menu-item"
  >
    <b-field
      v-if="isString"
      grouped
    >
      <b-field>
        <b-input
          :value="id"
          :title="path"
          disabled
        />
      </b-field>

      <locale-input
        v-for="(label, locale) in locales"
        :key="`${locale}.${path}`"
        :style="{ marginTop: '1em' }"
        :manual-doc="{module: 'locales', id: locale }"
        :field="path"
      />
    </b-field>

    <template
      v-if="hasChildren"
    >
      <p class="menu-label">
        {{ id }}
      </p>
      <ul
        class="menu-list"
      >
        <locales-node
          v-for="(node, id) in node"
          :id="id"
          :key="`localeNode-${id}`"
          :path="`${path}.${id}`"
          :node="node"
        />
      </ul>
    </template>
  </li>
</template>

<script>

import { isObject } from 'lodash'
import LocalesNode from './LocalesNode'
import LocaleInput from './LocaleInput'

const locales = {
  en: 'English',
  cs: 'Česky',
  de: 'Deutsch'
}

export default {
  name: 'LocalesNode',
  components: {
    LocalesNode,
    LocaleInput
  },
  props: {
    node: {
      required: true
    },
    id: {
      required: true
    },
    path: {
      required: true
    }
  },
  data() {
    return {
      locales
    }
  },
  computed: {
    hasChildren() {
      return typeof this.node === 'object'
    },
    isString() {
      return typeof this.node === 'string'
    }
  },
  methods: {
    isObject
  }
}
</script>
