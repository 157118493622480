import { mapGetters, mapState, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  props: {
    field: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoUpdate: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value () {
      return _.get(this.doc, this.field)
    },
    fieldType () {
      return (this.required && !this.value) ? 'is-warning' : 'is-default'
    }
  },
  methods: {
    update (newValue) {
      const params = this.manualDoc.id ? { id: this.manualDoc.id } : {}
      let newObj = _.assign({}, params, _.set({}, this.field, newValue))
      console.log(newObj)
      this.$store.dispatch(`${this.manualDoc.module}/patch`, newObj)
    }
  }
}
