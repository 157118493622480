module.exports = {
  common: {
    accomplish: '__MISSING_TRANSLATION__',
    add: '__MISSING_TRANSLATION__',
    address: '__MISSING_TRANSLATION__',
    back: '__MISSING_TRANSLATION__',
    city: '__MISSING_TRANSLATION__',
    close: '__MISSING_TRANSLATION__',
    column: '__MISSING_TRANSLATION__',
    contact: '__MISSING_TRANSLATION__',
    contacts: '__MISSING_TRANSLATION__',
    country: '__MISSING_TRANSLATION__',
    created_at: '__MISSING_TRANSLATION__',
    created_by: '__MISSING_TRANSLATION__',
    currency: '__MISSING_TRANSLATION__',
    date: '__MISSING_TRANSLATION__',
    delete: '__MISSING_TRANSLATION__',
    description: '__MISSING_TRANSLATION__',
    email: '__MISSING_TRANSLATION__',
    fill_all: '__MISSING_TRANSLATION__',
    from: '__MISSING_TRANSLATION__',
    internal_note: '__MISSING_TRANSLATION__',
    more: '__MISSING_TRANSLATION__',
    name: '__MISSING_TRANSLATION__',
    navigation: {
      export_to_csv: '__MISSING_TRANSLATION__',
      go_to_failure: '__MISSING_TRANSLATION__',
      reset_filters: '__MISSING_TRANSLATION__'
    },
    no_replacement: '__MISSING_TRANSLATION__',
    note: '__MISSING_TRANSLATION__',
    notes: '__MISSING_TRANSLATION__',
    number: '__MISSING_TRANSLATION__',
    official_name: '__MISSING_TRANSLATION__',
    ok: '__MISSING_TRANSLATION__',
    order: '__MISSING_TRANSLATION__',
    phone: '__MISSING_TRANSLATION__',
    progress: '__MISSING_TRANSLATION__',
    quick_search: '__MISSING_TRANSLATION__',
    regNr: '__MISSING_TRANSLATION__',
    replacement: '__MISSING_TRANSLATION__',
    replacement_number: '__MISSING_TRANSLATION__',
    reset: '__MISSING_TRANSLATION__',
    resolution: '__MISSING_TRANSLATION__',
    search: '__MISSING_TRANSLATION__',
    select_language: '__MISSING_TRANSLATION__',
    signature: '__MISSING_TRANSLATION__',
    state: '__MISSING_TRANSLATION__',
    to: '__MISSING_TRANSLATION__',
    updated_at: '__MISSING_TRANSLATION__',
    updated_by: '__MISSING_TRANSLATION__',
    vatNr: '__MISSING_TRANSLATION__',
    zip: '__MISSING_TRANSLATION__'
  },
  components: {
    aTable: {
      actions: '__MISSING_TRANSLATION__'
    },
    addDocButton: {
      title: '__MISSING_TRANSLATION__'
    },
    contacts: {
      form: {
        email: '__MISSING_TRANSLATION__',
        header: '__MISSING_TRANSLATION__',
        name: '__MISSING_TRANSLATION__',
        phone: '__MISSING_TRANSLATION__'
      }
    },
    createRepairListButton: {
      empty_repair_list: '__MISSING_TRANSLATION__',
      repair_list: '__MISSING_TRANSLATION__'
    },
    emptyTable: {
      text: '__MISSING_TRANSLATION__'
    },
    file: {
      confirm: '__MISSING_TRANSLATION__'
    },
    files: {
      header: '__MISSING_TRANSLATION__'
    },
    form: {
      aDatepicker: {
        header: '__MISSING_TRANSLATION__'
      },
      aUpload: {
        completed: '__MISSING_TRANSLATION__',
        drop_files_here: '__MISSING_TRANSLATION__',
        select_type: '__MISSING_TRANSLATION__',
        type: '__MISSING_TRANSLATION__'
      }
    },
    moreDocsButton: {
      all: '__MISSING_TRANSLATION__',
      more: '__MISSING_TRANSLATION__'
    },
    node: {
      delete: '__MISSING_TRANSLATION__',
      delete_part: '__MISSING_TRANSLATION__',
      duplicate: '__MISSING_TRANSLATION__',
      edit: '__MISSING_TRANSLATION__',
      make_node: '__MISSING_TRANSLATION__',
      partToNode: {
        confirm: '__MISSING_TRANSLATION__',
        prepare: '__MISSING_TRANSLATION__'
      },
      root: '__MISSING_TRANSLATION__',
      show: '__MISSING_TRANSLATION__'
    },
    nodeTree: {
      hide_headers: '__MISSING_TRANSLATION__',
      hide_parts: '__MISSING_TRANSLATION__'
    },
    typeVehicleAutocomplete: {
      label: '__MISSING_TRANSLATION__'
    }
  },
  customers: {
    pl: '__MISSING_TRANSLATION__',
    ref: {
      name: '__MISSING_TRANSLATION__'
    },
    sg: '__MISSING_TRANSLATION__'
  },
  dashboard: {
    estimated: {
      upcomingLimit: '__MISSING_TRANSLATION__'
    },
    sg: '__MISSING_TRANSLATION__',
    tabs: {
      approval_needed: '__MISSING_TRANSLATION__',
      upcoming: '__MISSING_TRANSLATION__'
    }
  },
  depots: {
    pl: '__MISSING_TRANSLATION__',
    sg: '__MISSING_TRANSLATION__'
  },
  failureNodes: {
    columns: {
      position: '__MISSING_TRANSLATION__',
      parent: {
        number: '__MISSING_TRANSLATION__',
        part: {
          name: '__MISSING_TRANSLATION__'
        }
      }
    },
    form: {
      number: {
        label: '__MISSING_TRANSLATION__',
        placeholder: '__MISSING_TRANSLATION__'
      },
      replacement: {
        label: '__MISSING_TRANSLATION__',
        placeholder: '__MISSING_TRANSLATION__'
      },
      visual_inspection: '__MISSING_TRANSLATION__'
    },
    options: {
      state: {
        completed: '__MISSING_TRANSLATION__',
        received: '__MISSING_TRANSLATION__',
        repaired: '__MISSING_TRANSLATION__',
        repairing: '__MISSING_TRANSLATION__'
      }
    },
    parent: {
      header: '__MISSING_TRANSLATION__'
    },
    pl: '__MISSING_TRANSLATION__',
    props: {
      number_array: '__MISSING_TRANSLATION__',
      progress: {
        completed: {
          at: '__MISSING_TRANSLATION__',
          by: '__MISSING_TRANSLATION__'
        },
        repaired: {
          at: '__MISSING_TRANSLATION__',
          by: '__MISSING_TRANSLATION__'
        }
      },
      replacement: {
        number: '__MISSING_TRANSLATION__'
      }
    },
    ref: {
      service: {
        inspection: '__MISSING_TRANSLATION__',
        repair: '__MISSING_TRANSLATION__',
        testing: '__MISSING_TRANSLATION__'
      }
    },
    serial_number: '__MISSING_TRANSLATION__',
    sg: '__MISSING_TRANSLATION__'
  },
  failureParts: {
    columns: {
      parent: {
        number: '__MISSING_TRANSLATION__',
        part: {
          name: '__MISSING_TRANSLATION__'
        }
      },
      progress: {
        dispatched: {
          at: '__MISSING_TRANSLATION__',
          by: '__MISSING_TRANSLATION__'
        },
        repaired: {
          at: '__MISSING_TRANSLATION__',
          by: '__MISSING_TRANSLATION__'
        }
      },
      replacement: {
        number: '__MISSING_TRANSLATION__',
        part: {
          description: '__MISSING_TRANSLATION__',
          name: '__MISSING_TRANSLATION__',
          supplier: {
            name: '__MISSING_TRANSLATION__'
          }
        },
        revision: '__MISSING_TRANSLATION__'
      }
    },
    form: {
      remove_prepare: '__MISSING_TRANSLATION__'
    },
    options: {
      reason: {
        electrical_damage: '__MISSING_TRANSLATION__',
        mechanical_damage: '__MISSING_TRANSLATION__',
        other_requirements: '__MISSING_TRANSLATION__',
        preventive_replacement: '__MISSING_TRANSLATION__'
      },
      solution: {
        external_repair: '__MISSING_TRANSLATION__',
        internal_repair: '__MISSING_TRANSLATION__',
        liquidation: '__MISSING_TRANSLATION__'
      },
      state: {
        completed: '__MISSING_TRANSLATION__',
        received: '__MISSING_TRANSLATION__',
        repaired: '__MISSING_TRANSLATION__',
        repairing: '__MISSING_TRANSLATION__'
      }
    },
    parent: {
      select: '__MISSING_TRANSLATION__'
    },
    pl: '__MISSING_TRANSLATION__',
    props: {
      number_array: '__MISSING_TRANSLATION__',
      position: '__MISSING_TRANSLATION__',
      progress: {
        completed: {
          at: '__MISSING_TRANSLATION__',
          by: '__MISSING_TRANSLATION__'
        },
        repaired: {
          at: '__MISSING_TRANSLATION__',
          by: '__MISSING_TRANSLATION__'
        }
      },
      reason: '__MISSING_TRANSLATION__',
      revision: '__MISSING_TRANSLATION__',
      solution: '__MISSING_TRANSLATION__'
    },
    sg: '__MISSING_TRANSLATION__'
  },
  failureSupplies: {
    columns: {
      issuer: {
        name: '__MISSING_TRANSLATION__'
      }
    },
    form: {
      generate_delivery_note: '__MISSING_TRANSLATION__',
      header: '__MISSING_TRANSLATION__',
      remove_confirm: '__MISSING_TRANSLATION__',
      remove_success: '__MISSING_TRANSLATION__'
    },
    options: {
      state: {
        completed: '__MISSING_TRANSLATION__',
        dispatched: '__MISSING_TRANSLATION__',
        draft: '__MISSING_TRANSLATION__',
        repaired: '__MISSING_TRANSLATION__'
      }
    },
    pl: '__MISSING_TRANSLATION__',
    props: {
      issuer: '__MISSING_TRANSLATION__'
    },
    ref: {
      number: '__MISSING_TRANSLATION__'
    }
  },
  failures: {
    addButton: {
      enter_information: '__MISSING_TRANSLATION__',
      node: '__MISSING_TRANSLATION__',
      part: '__MISSING_TRANSLATION__'
    },
    columns: {
      progress: {
        reported: {
          by: {
            name: '__MISSING_TRANSLATION__'
          }
        }
      },
      service: {
        travel_distance: '__MISSING_TRANSLATION__',
        travel_hours: '__MISSING_TRANSLATION__',
        work_hours: '__MISSING_TRANSLATION__'
      }
    },
    dispatchFailureParts: {
      make_delivery_note: '__MISSING_TRANSLATION__',
      notes: '__MISSING_TRANSLATION__'
    },
    external_contact: '__MISSING_TRANSLATION__',
    failureParts: {
      generate_supply_for: '__MISSING_TRANSLATION__',
      remove_node_confirm: '__MISSING_TRANSLATION__',
      select_supplier: '__MISSING_TRANSLATION__'
    },
    filters: {
      vehicle: '__MISSING_TRANSLATION__'
    },
    form: {
      accomplish: '__MISSING_TRANSLATION__',
      before_leave: '__MISSING_TRANSLATION__',
      complete_confirm: '__MISSING_TRANSLATION__',
      complete_success: '__MISSING_TRANSLATION__',
      external_contacts: '__MISSING_TRANSLATION__',
      remove_success: '__MISSING_TRANSLATION__',
      remove_warning: '__MISSING_TRANSLATION__',
      remove_warning_2: '__MISSING_TRANSLATION__',
      remove_warning_3: '__MISSING_TRANSLATION__'
    },
    mail: {
      delivery_error: '__MISSING_TRANSLATION__',
      receipt: {
        create_attachment: '__MISSING_TRANSLATION__',
        create_fullpaper_attachment: '__MISSING_TRANSLATION__',
        estimated_price: '__MISSING_TRANSLATION__',
        fill_in_recepients: '__MISSING_TRANSLATION__',
        final_price: '__MISSING_TRANSLATION__',
        header: '__MISSING_TRANSLATION__',
        include_in_this_overview: '__MISSING_TRANSLATION__',
        not_visible_in_PDF: '__MISSING_TRANSLATION__',
        recipients: '__MISSING_TRANSLATION__',
        send_again: '__MISSING_TRANSLATION__',
        sending_mail: '__MISSING_TRANSLATION__',
        sending_state: {
          creating_attachment_error: '__MISSING_TRANSLATION__',
          creating_attachment_success: '__MISSING_TRANSLATION__',
          sending_email_error: '__MISSING_TRANSLATION__',
          sending_email_success: '__MISSING_TRANSLATION__'
        }
      },
      remove_confirm: '__MISSING_TRANSLATION__',
      remove_message: '__MISSING_TRANSLATION__'
    },
    mails: {
      header: '__MISSING_TRANSLATION__',
      overview: '__MISSING_TRANSLATION__',
      receipt: '__MISSING_TRANSLATION__',
      send_button: '__MISSING_TRANSLATION__',
      send_receipt: '__MISSING_TRANSLATION__'
    },
    newNodeOrPart: {
      add_node: '__MISSING_TRANSLATION__',
      add_part: '__MISSING_TRANSLATION__',
      header: '__MISSING_TRANSLATION__',
      select_parent: '__MISSING_TRANSLATION__'
    },
    noteForm: {
      header: '__MISSING_TRANSLATION__',
      type_a_note: '__MISSING_TRANSLATION__'
    },
    options: {
      location: {
        cegelec: '__MISSING_TRANSLATION__',
        site: '__MISSING_TRANSLATION__',
        vehicle: '__MISSING_TRANSLATION__'
      },
      recognized: {
        no: '__MISSING_TRANSLATION__',
        yes: '__MISSING_TRANSLATION__'
      },
      state: {
        completed: '__MISSING_TRANSLATION__',
        draft: '__MISSING_TRANSLATION__',
        reported: '__MISSING_TRANSLATION__',
        serviced: '__MISSING_TRANSLATION__'
      },
      type: {
        guarantee: '__MISSING_TRANSLATION__',
        post_guarantee: '__MISSING_TRANSLATION__'
      }
    },
    pl: '__MISSING_TRANSLATION__',
    progressBar: {
      completed_at: '__MISSING_TRANSLATION__',
      estimated_at: '__MISSING_TRANSLATION__',
      repair_time: '__MISSING_TRANSLATION__',
      reported: '__MISSING_TRANSLATION__',
      unresolved_time: '__MISSING_TRANSLATION__'
    },
    props: {
      contract_number: '__MISSING_TRANSLATION__',
      description: '__MISSING_TRANSLATION__',
      location: '__MISSING_TRANSLATION__',
      name: '__MISSING_TRANSLATION__',
      notified: '__MISSING_TRANSLATION__',
      number: '__MISSING_TRANSLATION__',
      number_array: '__MISSING_TRANSLATION__',
      odometer: '__MISSING_TRANSLATION__',
      order_number: '__MISSING_TRANSLATION__',
      order_number_array: '__MISSING_TRANSLATION__',
      progress: {
        completed: {
          at: '__MISSING_TRANSLATION__',
          by: '__MISSING_TRANSLATION__'
        },
        estimated: {
          at: '__MISSING_TRANSLATION__',
          by: '__MISSING_TRANSLATION__',
          history: '__MISSING_TRANSLATION__'
        },
        reported: {
          at: '__MISSING_TRANSLATION__',
          by: '__MISSING_TRANSLATION__'
        },
        serviced: {
          at: '__MISSING_TRANSLATION__',
          by: '__MISSING_TRANSLATION__'
        }
      },
      recognized: '__MISSING_TRANSLATION__',
      resolution: '__MISSING_TRANSLATION__',
      service: {
        distance: '__MISSING_TRANSLATION__',
        inspection: '__MISSING_TRANSLATION__',
        repair: '__MISSING_TRANSLATION__',
        testing: '__MISSING_TRANSLATION__',
        travel: '__MISSING_TRANSLATION__'
      },
      state: '__MISSING_TRANSLATION__',
      type: '__MISSING_TRANSLATION__'
    },
    ref: {
      name: '__MISSING_TRANSLATION__',
      number: '__MISSING_TRANSLATION__',
      type: '__MISSING_TRANSLATION__',
      service: {
        inspection: '__MISSING_TRANSLATION__',
        repair: '__MISSING_TRANSLATION__',
        testing: '__MISSING_TRANSLATION__'
      }
    },
    repairListForm: {
      generate_empty_repair_list: '__MISSING_TRANSLATION__',
      generate_repair_list: '__MISSING_TRANSLATION__',
      header: '__MISSING_TRANSLATION__'
    },
    resolutionForm: {
      header: '__MISSING_TRANSLATION__',
      type: '__MISSING_TRANSLATION__'
    },
    select_estimated: '__MISSING_TRANSLATION__',
    select_reported: '__MISSING_TRANSLATION__',
    service: {
      header: '__MISSING_TRANSLATION__',
      inspection: {
        label: '__MISSING_TRANSLATION__',
        placeholder: '__MISSING_TRANSLATION__'
      },
      repair: {
        label: '__MISSING_TRANSLATION__',
        placeholder: '__MISSING_TRANSLATION__'
      },
      testing: {
        label: '__MISSING_TRANSLATION__',
        placeholder: '__MISSING_TRANSLATION__'
      },
      totalWorkTime: '__MISSING_TRANSLATION__',
      travelTimeDistance: '__MISSING_TRANSLATION__'
    },
    service_team: '__MISSING_TRANSLATION__',
    sg: '__MISSING_TRANSLATION__',
    state: {
      header: '__MISSING_TRANSLATION__'
    },
    team_member: '__MISSING_TRANSLATION__',
    vehicle: {
      add: '__MISSING_TRANSLATION__',
      customer: '__MISSING_TRANSLATION__',
      prepare: '__MISSING_TRANSLATION__'
    },
    warranty: '__MISSING_TRANSLATION__'
  },
  header: {
    login: '__MISSING_TRANSLATION__',
    signup: '__MISSING_TRANSLATION__'
  },
  layout: {
    settings: {
      header: '__MISSING_TRANSLATION__',
      reset: '__MISSING_TRANSLATION__'
    },
    sidebar: {
      recent_failures: '__MISSING_TRANSLATION__',
      tools: '__MISSING_TRANSLATION__'
    }
  },
  operators: {
    overview: '__MISSING_TRANSLATION__',
    pl: '__MISSING_TRANSLATION__',
    ref: {
      name: '__MISSING_TRANSLATION__'
    },
    sg: '__MISSING_TRANSLATION__'
  },
  parts: {
    pl: '__MISSING_TRANSLATION__',
    ref: {
      description: '__MISSING_TRANSLATION__',
      name: '__MISSING_TRANSLATION__',
      number: '__MISSING_TRANSLATION__',
      select: '__MISSING_TRANSLATION__'
    },
    sg: '__MISSING_TRANSLATION__'
  },
  pdf: {
    order_form: {
      contact_person: '__MISSING_TRANSLATION__',
      contract_number: '__MISSING_TRANSLATION__',
      estimate_warning: '__MISSING_TRANSLATION__',
      heading: '__MISSING_TRANSLATION__',
      products: {
        description: '__MISSING_TRANSLATION__',
        estimated_price: '__MISSING_TRANSLATION__',
        heading: '__MISSING_TRANSLATION__',
        number: '__MISSING_TRANSLATION__',
        type: '__MISSING_TRANSLATION__'
      },
      purchase_number: '__MISSING_TRANSLATION__',
      received_at: '__MISSING_TRANSLATION__',
      solution_description: '__MISSING_TRANSLATION__'
    },
    repair_list: {
      approved_by: '__MISSING_TRANSLATION__',
      header: '__MISSING_TRANSLATION__',
      hours: '__MISSING_TRANSLATION__',
      products: {
        description: '__MISSING_TRANSLATION__',
        header: '__MISSING_TRANSLATION__',
        number: '__MISSING_TRANSLATION__',
        position: '__MISSING_TRANSLATION__',
        replaced: '__MISSING_TRANSLATION__',
        used: '__MISSING_TRANSLATION__'
      },
      stamp: '__MISSING_TRANSLATION__',
      work_description: '__MISSING_TRANSLATION__'
    }
  },
  ramsNodes: {
    pl: '__MISSING_TRANSLATION__',
    props: {
      daysInOperation: '__MISSING_TRANSLATION__',
      decomposition: '__MISSING_TRANSLATION__',
      failure: {
        progress: {
          reported: {
            at: '__MISSING_TRANSLATION__'
          }
        }
      },
      name: '__MISSING_TRANSLATION__',
      number_array: '__MISSING_TRANSLATION__',
      operating_since: '__MISSING_TRANSLATION__'
    }
  },
  suppliers: {
    pl: '__MISSING_TRANSLATION__',
    ref: {
      name: '__MISSING_TRANSLATION__'
    },
    sg: '__MISSING_TRANSLATION__'
  },
  users: {
    options: {
      role: {
        admin: '__MISSING_TRANSLATION__',
        customer: '__MISSING_TRANSLATION__',
        reader: '__MISSING_TRANSLATION__',
        technician: '__MISSING_TRANSLATION__'
      }
    },
    pl: '__MISSING_TRANSLATION__',
    props: {
      locale: '__MISSING_TRANSLATION__',
      name: '__MISSING_TRANSLATION__',
      recentFailures: '__MISSING_TRANSLATION__',
      role: '__MISSING_TRANSLATION__'
    }
  },
  vehicleTypes: {
    pl: '__MISSING_TRANSLATION__',
    props: {
      description: '__MISSING_TRANSLATION__'
    },
    ref: {
      description: '__MISSING_TRANSLATION__',
      name: '__MISSING_TRANSLATION__'
    },
    sg: '__MISSING_TRANSLATION__'
  },
  vehicles: {
    newVehicle: {
      confirm: '__MISSING_TRANSLATION__',
      customer: {
        placeholder: '__MISSING_TRANSLATION__'
      },
      number: {
        placeholder: '__MISSING_TRANSLATION__'
      },
      operator: {
        placeholder: '__MISSING_TRANSLATION__'
      },
      vehicleType: {
        placeholder: '__MISSING_TRANSLATION__'
      }
    },
    pl: '__MISSING_TRANSLATION__',
    ref: {
      number: '__MISSING_TRANSLATION__'
    },
    sg: '__MISSING_TRANSLATION__'
  }
}
