<template>
  <a-card
    :editable="false"
  >
    <template
      #header-buttons
    />

    <b-tabs>
      <b-tab-item
        v-for="(parentNode, parentId) in localesTree"
        :key="parentId"
        :label="parentId"
      >
        <div class="menu">
          <ul class="menu-list">
            <locales-node
              v-for="(node, id) in parentNode"
              :id="id"
              :key="`localeNode-${id}`"
              :path="`${parentId}.${id}`"
              :node="node"
            />
          </ul>
        </div>
      </b-tab-item>
    </b-tabs>
  </a-card>
</template>

<script>
import _ from 'lodash'
import LocalesNode from './Locales/LocalesNode'
import localesTree from './Locales/localesTree'

export default {
  components: {
    LocalesNode
  },
  data() {
    return {
      localesTree
    }
  },
  computed: {
    tabs() {
      return _.omit(this.localesTree, ['id', 'created_at', 'updated_at', 'updated_by'])
    }
  },
  async created () {
    await this.$store.dispatch(`locales/fetchAll`)
  }
}
</script>`
